/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useContext, } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
// import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { SidebarSearch } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarContext,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  // SidebarScrollWrapper,
} from '@backstage/core-components';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    React.createElement('div', { className: classes.root,}
      , React.createElement(Link, {
        component: NavLink,
        to: "/",
        underline: "none",
        className: classes.link,}
      
        , isOpen ? React.createElement(LogoFull, null ) : React.createElement(LogoIcon, null )
      )
    )
  );
};

export const Root = ({ children }) => (
  React.createElement(SidebarPage, null
    , React.createElement(Sidebar, null
      , React.createElement(SidebarLogo, null )
      , React.createElement(SidebarSearch, null )
      , React.createElement(SidebarDivider, null )
      /* Global nav, not org-specific */
      , React.createElement(SidebarItem, { icon: HomeIcon, to: "catalog", text: "Home",} )
      , React.createElement(SidebarItem, { icon: ExtensionIcon, to: "api-docs", text: "APIs",} )
      , React.createElement(SidebarItem, { icon: LibraryBooks, to: "docs", text: "Docs",} )
      , React.createElement(SidebarItem, { icon: CreateComponentIcon, to: "create", text: "Create...",} )
      /* End global nav */
      , React.createElement(SidebarDivider, null )
      /* <SidebarScrollWrapper>
        <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
      </SidebarScrollWrapper> */
      , React.createElement(SidebarSpace, null )
      , React.createElement(SidebarDivider, null )
      , React.createElement(SidebarSettings, null )
    )
    , children
  )
);
